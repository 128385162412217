import React, { useState, useEffect, useContext } from 'react';
import { StyleSheet, View,Text, TouchableOpacity, Dimensions,  SafeAreaView, } from 'react-native';
import Modal from 'react-native-modal';
import HomeLogo from '../assets/images/svg/homelogo.svg';
import keenenterpirses from '../assets/images/logo.jpg'
import MenuIcon from '../assets/images/svg/menu.svg';
import colours from '../config/colours';
import { useNavigation } from '@react-navigation/native';

import Menu from './menu'

const { height, width } = Dimensions.get('window')

const Header = () => {




    const [isModalVisibleContact, setModalVisibleContact] = useState(false);

    const navigation = useNavigation();
    const toggleModal = () => {
      setModalVisibleContact(!isModalVisibleContact);
    };
  return (
    
      <View style={styles.headerContainer}>

                            <View  style={styles.headerItemLeft}>

                                      <TouchableOpacity  
                                          onPress={() => navigation.navigate('Home')}
                                          style={{paddingRight:15}}
                                        >
                                            <img src={HomeLogo} width={width>height?30:25}  />
                                      </TouchableOpacity>



                                        <TouchableOpacity 
                                          onPress={() => navigation.navigate('Home')} 
                                            >
                                        
                                        <img src={keenenterpirses} width={width>height?120:110} />

                                        </TouchableOpacity>

                             </View>




  
                    
                    <View style={styles.headerItemRight}>


                        <TouchableOpacity  
                          onPress={() => {setModalVisibleContact(!isModalVisibleContact)}}
                        >
                            <img src={MenuIcon} width={width>height?40:35}  />
                        </TouchableOpacity>
                            
                    </View>







                    <Modal 
                      isVisible={isModalVisibleContact} 
                      transparent={true}
                      onBackdropPress={()=>setModalVisibleContact(!isModalVisibleContact)}
                      animationIn={'fadeIn'}
                      animationOut={'fadeOut'}
                      style={styles.modalContainer}
                      >
                              <View>
                                <Menu
                                toggleModal={toggleModal}
                                />
                              </View>
                      </Modal>
          
    </View>


  );
};



const styles = StyleSheet.create({

  headerContainer:
  {
   flex: 1,
    flexDirection: 'row',
   justifyContent: 'center',
   height: width > 1001 ? 67 : 65,
    width: width<1000?'100vw':1000,
  },

  headerItemLeft:
  {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginHorizontal: 20,
    marginTop: 15,
  },

  headerItemRight:
  {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginHorizontal: 10,
    alignItems: 'center'

  },


  modalContainer: {
    flex: 1,
    flexDirection: 'column',
   backgroundColor: 'rgba(0,0,0,0.7)',
    justifyContent: 'flex-start',
    alignSelf:'center',
    width: width<1000?'100%':1000,
    margin: 0,
  },

});


export default Header;

