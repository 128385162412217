

import React, { useState,useContext } from 'react';
import { ScrollView, View, Text, Dimensions, StyleSheet,TouchableOpacity } from "react-native";
import colours from '../config/colours'
import { useNavigation } from '@react-navigation/native';

const { height, width } = Dimensions.get('window')

const Menu = ({ toggleModal}) => {
    const navigation = useNavigation();

  const [isModalVisible, setModalVisible] = useState(true);


return(
 
    <ScrollView >
  <View style={styles.menuContainerMain}>



                <TouchableOpacity 
                //onPress={() => {Home(),toggleModal()}} 
                onPress={toggleModal} 
                >
                    
                    <View style={styles.headerItemLeft}>
                    <Text style={styles.closeText}>X</Text>
                    </View>

                    </TouchableOpacity>



                    <TouchableOpacity
      onPress={() => {toggleModal() ,navigation.navigate('Home')}} 

  >
  <View style={styles.menuContainer}>
            <Text style={styles.menuText}> HOME </Text>
  </View>
  </TouchableOpacity>


  <TouchableOpacity
      onPress={() => {toggleModal() ,navigation.navigate('About Us')}} 

  >
  <View style={styles.menuContainer}>
            <Text style={styles.menuText}> ABOUT US </Text>
  </View>
  </TouchableOpacity>

  <TouchableOpacity 
   onPress={() => {toggleModal() ,navigation.navigate('Services')}} 
    >
  <View style={styles.menuContainer}>
            <Text style={styles.menuText}> SERVICES </Text>
  </View>
  </TouchableOpacity>

  <TouchableOpacity 
   onPress={() => {toggleModal() ,navigation.navigate('Experience')}} 
    >
  <View style={styles.menuContainer}>
            <Text style={styles.menuText}> EXPERIENCE </Text>
  </View>
  </TouchableOpacity>




  <TouchableOpacity
    onPress={() => {toggleModal() ,navigation.navigate('Contact')}} 

  >
  <View style={styles.menuContainer}>
            <Text style={styles.menuText}> CONTACT US </Text>
  </View>
  </TouchableOpacity>
  


  </View>
            </ScrollView >

    )};

export default Menu;

const styles = StyleSheet.create({

    menuContainerMain: {
        alignSelf:'flex-end',
        width:300,
        heigh:height,
        //backgroundColor:colours.primaryBlack
    },

    menuContainer: {
        width:'12rem',
        alignSelf:'center',
        alignItems:'center',
    },

    closeContainer: {
        backgroundColor: colours.primary,
        borderRadius: 5,
        marginTop:20,
        paddingHorizontal: 20,
        paddingVertical: 20,
        alignSelf:'center',
    },
 
    closeText: {
        fontSize: '1.7rem',
        fontWeight:'200',
        color: colours.white,
    },



    menuText: {
        marginVertical: '.6rem',
        color: colours.white,
        fontSize: '1.2rem',
        fontWeight: '300'
    },
    menuTextCS: {
      marginVertical: '.6rem',
      color: colours.primaryLight,
      fontSize: '1.2rem',
      fontWeight: '300'
  },
    headerItemLeft:
    {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginVertical: 20,
      marginHorizontal:width>1000?10:10,
      borderWidth:1,
      alignSelf:'flex-end',
      paddingHorizontal:10,
      paddingVertical:3,
      borderRadius:3,
      borderColor:colours.primaryLight
  
    },



});


