import React,{useState,useContext} from 'react';
import { StyleSheet,View,TextInput, Image, Text, TouchableOpacity, Dimensions,SafeAreaView,Linking} from 'react-native';
import Modal from 'react-native-modal';

import colours from '../config/colours';
import Header from '../components/header'
import Footer from '../components/footer'
import { useNavigation } from '@react-navigation/native';
import { ScrollView } from 'react-native-gesture-handler';
//import { firebase } from '../config/firebase'
import PhoneIcon from '../assets/images/svg/phone.svg'

import EmailIcon from '../assets/images/svg/email.svg'
import Whatsapp from '../assets/images/social/Whatsapp.svg'
import FooterContact from '../components/footerContact';

const { height, width } = Dimensions.get('window')


const ContactPage = () => {



  const navigation = useNavigation();

  const whatsappURL = 'https://api.whatsapp.com/send?phone=971501310408'

  const [loading, setLoading] = useState(false);


    
    const [fullName, setFullName] = useState('');
    const [messageText, setMessageText] = useState('');
    const [contactText, setcontactText] = useState('');
    const [mobileText, setMobileText] = useState('');
    const [errorModal,setErrorModal]=useState(false)
    const [errorText,setErrorText]=useState('')
    const [messageTextHeight, setMessageTextHeight] = useState(80);




    const saveMessages = async () => {
      setLoading(true);alert('Message Sent successfully! We will reply soon')
     // let initialQuery = await firebase.firestore().collection('querymessages')
      //.doc(user?firebase.auth().currentUser.uid:(Math.round(new Date() / 1000)).toString())
      //.set({
       //         pageName:`${leagueName}`,
       //         Email: contactText, 
       //         Mobile:mobileText, 
      //          Name: fullName, 
      //          Messages:messageText, 
      //          MessageReceiveTime:firebase.firestore.Timestamp.fromDate(new Date()),
      // },{merge: true})
      //.then(alert('Message Sent successfully! We will reply soon'))
      
    }
    
    
    




  return (
    <SafeAreaView>
      <ScrollView>
        <View style={styles.safeView}>

          <View><Header /></View>

          <View style={styles.bannerBody}>






            <Text style={[styles.titleText, { fontSize: '2rem' }]}>
              Contact Us
                      </Text>
<View style={styles.containerContactUs}>

                      <Text style={[styles.titleText,{color:colours.primaryGrey}]}>
            Email : hala@keenenter.com {'\n'}
            Phone : +971 4 329 1314{'\n'}
            Mobile : +971 50 1310 408{'\n'}
                      </Text>


                      <View style={styles.followContainer}>



                          <View style={styles.followContainerItems}>

                          <Text accessibilityRole="link" href={whatsappURL}  target="_blank" ><img src={Whatsapp} width={50} /> </Text>
                          </View>



                          <View style={styles.followContainerItems}>


                          <Text accessibilityRole="link" href={'mailto:hala@keenenter.com'}  target="_blank" ><img src={EmailIcon} width={50}  /> </Text>
                          </View>

                          <View style={styles.followContainerItems}>


                          <Text accessibilityRole="link" href={'tel:+971501310408'} ><img src={PhoneIcon} width={50} /></Text>
                          </View>

                          </View>



</View>
            <Text style={[styles.titleText, { marginBottom: 0, fontSize: '.8rem', fontWeight: '300' }]}>
              We are here to help and answer any question you might have. We look forwrd to hearing from you.
                      </Text>


            <View style={styles.containerContactUs}>
              <Text style={styles.containerContactUstitle} >
                                  For all enquiries, please email us using the form below
                                  </Text>

              <TextInput style={[styles.profileText, { height: messageTextHeight }]}
                adjustsFontSizeToFit={true}
                allowFontScaling={true}
                onContentSizeChange={(event) => { setMessageTextHeight(event.nativeEvent.contentSize.height) }}
                onChangeText={(editedText) => { setMessageText(editedText) }}
                multiline={true}
                value={messageText}
                placeholder={'Message'}
              />

              <TextInput style={styles.profileText}
                onChangeText={(editedText) => { setFullName(editedText) }}
                //editable={profileEditMode? true:false}
                value={fullName}
                placeholder={'Name'}
              />


              <TextInput style={styles.profileText}
                onChangeText={(editedText) => { setcontactText(editedText) }}
                //editable={profileEditMode? true:false}
                value={contactText}
                placeholder={'Email'}
              />
              <TextInput style={styles.profileText}
                onChangeText={(editedText) => { setMobileText(editedText) }}
                keyboardType='numeric'
                value={mobileText}
                placeholder={'Mobile No'}

              />


              <TouchableOpacity
                onPress={() => {
                  messageText ?
                    contactText ?
                      mobileText ?
                        isNaN(mobileText) ? [setErrorModal(true), setErrorText('Please provide correct mobile number')] :
                          mobileText.length < 9 ? [setErrorModal(true), setErrorText('Please provide correct mobile number')] :
                            fullName ?
                              [saveMessages(), console.log('Success')]
                              : [setErrorModal(true), setErrorText('Did you forget to put you name! \n Please write your name')]
                        : [setErrorModal(true), setErrorText('Mobile Phone Number Empty! \n Please provide a number so we can contact you.')]
                      : [setErrorModal(true), setErrorText('Email Empty! \n Please let us know your valid email id')]
                    : [setErrorModal(true), setErrorText('Message Empty !\n Please write something')]

                }}
              >      <View style={styles.registerButtonBox}>
                  <Text style={styles.registerButtonBoxText} >
                    Submit
                                  </Text>
                </View>
              </TouchableOpacity>

            </View>







            <Modal
              isVisible={errorModal}
              transparent={true}
              onBackdropPress={() => setErrorModal(false)}
              animationIn={'fadeIn'}
              animationOut={'fadeOut'}
              style={styles.containerModal}
            >
              <Text style={styles.containerModalError}>
                {errorText}</Text>
              <TouchableOpacity
                onPress={() => setErrorModal(!errorModal)}
                style={{ backgroundColor: 'darkred', borderRadius: 10, paddingVertical: 3, paddingHorizontal: 10 }}
              >

                <Text style={{
                  paddingHorizontal: 15,
                  paddingVertical: 10,
                  fontWeight: '600',
                  alignSelf: 'center',
                  color: 'white'
                }
                }>Close</Text>

              </TouchableOpacity>

            </Modal>







          </View>

          <View><FooterContact /></View>

        </View>
      </ScrollView>
    </SafeAreaView>

  );
};



const styles = StyleSheet.create({
  safeView: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    //height: '100vh',
  },
  bannerBody: {
    flex: 1,
    alignSelf:'center',
    maxWidth:1000,
    alignItems:width<1001? 'center':'center',
  },
  titleText: {
    fontSize: '.9rem',
    fontWeight:'400',
    alignSelf:'center',
    lineHeight:35,
    color: colours.lightGrey,
    paddingHorizontal:20,
  },
  containerContactUs: {
    alignItems:'center',
    padding:20,
    marginTop:20,
    marginBottom:10,
    minWidth:width>1000?550:'85vw',
    maxWidth:500,
    backgroundColor:colours.secondaryBlack,
  },
  containerContactUstitle: {
    fontSize: '.9rem',
    fontWeight:'400',
    color: colours.primaryGrey,
    textAlign:'center',
    paddingBottom:5,
    marginVertical:20,
  },
  profileText: {
    fontSize: '.9rem',
    fontWeight:'400',
    color: colours.white,
    marginVertical:5,
    paddingHorizontal:10,
    backgroundColor:colours.tertiaryBlack,
    borderRadius:5,
    minWidth:width>1000?400:'75vw',
    maxWidth:500,
    textAlign:'left',
    marginBottom:10,
    marginTop:2,
    padding:10
  },
  registerButtonBox: {
    flex:1,
    justifyContent:'center',
    backgroundColor:colours.tertiaryBlack,
    borderRadius:'.4rem',
    height:'2.7rem',
    paddingHorizontal:20,
  },
  
  registerButtonBoxText: {
    fontSize: '1.2rem',
    fontWeight:'600',
    color: colours.white,
    marginHorizontal:10,
  },
  containerModal: {
    alignItems:'center',
    backgroundColor:colours.primaryBlack,
    alignSelf:'center',
    maxHeight:250,
    borderRadius:10,
  },
  containerModalError: {
    fontSize: '.9rem',
    fontWeight:'400',
    color: colours.primaryGrey,
    paddingHorizontal:20,
    paddingVertical:20
  },


  followContainer: {
    flex:1,
    flexDirection:'row',
    marginVertical: 15,
    alignSelf:'center',
    paddingBottom:10,
  },

  followContainerItemsText: {
    flex:1,
    alignContent:'center' ,    
    marginTop:10,
    margin:50

  },
  followContainerItems: 
  {
    flex:1,
    alignContent:'center' ,  
    marginLeft:15,  
  },



});


export default ContactPage;




