
import React, { useEffect } from 'react';
import { StyleSheet, View, Image, Text, TouchableOpacity, Dimensions, SafeAreaView, Linking } from 'react-native';

import colours from '../config/colours';
import Header from '../components/header'
import Footer from '../components/footer'
import { useNavigation } from '@react-navigation/native';
import { ScrollView } from 'react-native-gesture-handler';

import image2 from '../assets/banners/2.jpg'
import image3 from '../assets/banners/3.jpg'
import image4 from '../assets/banners/4.jpg'
import image5 from '../assets/banners/5.jpg'
import image6 from '../assets/banners/6.jpg'
const { height, width } = Dimensions.get('window')


const AboutPage = () => {



  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const navigation = useNavigation();

  return (
    <SafeAreaView>
      <ScrollView>
        <View style={styles.safeView}>

          <View><Header /></View>

          <View>
            <View style={styles.categoryTitle}>
              <img src={image2} width={width > 1000 ? 1000 : width} />
            </View>
            <View style={styles.categoryTitle}>
              <img src={image3} width={width > 1000 ? 1000 : width} />
            </View>
            <View style={styles.categoryTitle}>
              <img src={image4} width={width > 1000 ? 1000 : width} />
            </View>
            <View style={styles.categoryTitle}>
              <img src={image5} width={width > 1000 ? 1000 : width} />
            </View>
            <View style={styles.categoryTitle}>
              <img src={image6} width={width > 1000 ? 1000 : width} />
            </View>
          </View>

          <View><Footer /></View>

        </View>
      </ScrollView>
    </SafeAreaView>

  );
};



const styles = StyleSheet.create({
  safeView: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: colours.primaryBlack,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100vh',

  },

  categoryTitle: {
    flex: 4,
    justifyContent: 'center',
    marginVertical: 20,
  },




});






export default AboutPage;
  
  


