import React, { useState, useEffect,useCallback } from 'react';
import { StyleSheet,View,Button, Image, Text, TouchableOpacity, Dimensions, FlatList, ActivityIndicator, RefreshControl,ScrollView,SafeAreaView , Linking} from 'react-native';
import Modal from 'react-native-modal';

import colours from '../config/colours';
import { useNavigation } from '@react-navigation/native';

import Instagram from '../assets/images/social/Instagram.svg'
import Facebook from '../assets/images/social/Facebook.svg'

const { height, width } = Dimensions.get('window')

const FooterContact = () => {


    const navigation = useNavigation();

    const velocitiaURL = 'https://velocitia.com'
    const facebookURL = '#'
    const instagramURL = '#'





    return (
<View>

      
                            

<View style={styles.followContainerSocial}>


          
                            <View style={styles.followContainerItems}>


                            <Text accessibilityRole="link" href={instagramURL}  target="_blank" ><img src={Instagram} width={35}  /> </Text>
                            </View>

                            <View style={styles.followContainerItems}>


                            <Text accessibilityRole="link" href={facebookURL}  target="_blank" ><img src={Facebook} width={35} /></Text>
                            </View>


</View>
      
                            




            <View style={styles.footer}>
                    <Text  style={styles.footerText} >
                        © Copyright  {new Date().getFullYear()} | Keen Enterprises | All rights reserved
                    </Text>
                </View> 




</View>


    );
  };



const styles = StyleSheet.create({



  footer: {
    flex: 1,
    justifyContent: 'flex-end',
    marginTop:50,
    marginBottom:20
  },
  footerText: {
    fontSize: '.7rem',
    color: colours.lightGrey,
    textAlign: 'center',
    fontWeight: '400'
  },



  followContainer: {
    flex:1,
    flexDirection:'row',
    marginVertical: 5,
    alignSelf:'center',
    paddingBottom:10,
  },

  followContainerItemsText: {
    flex:1,
    alignContent:'center' ,    
    marginTop:10

  },

followText: {
  fontSize: 15,
  color: colours.lightGrey,
  fontWeight: '600',

},

  followContainerItems: 
  {
    flex:1,
    alignContent:'center' ,  
    marginLeft:5,  
  },
  followContainerSocial: 
  {
    flex:1,
    flexDirection:'row',
    marginTop: 20,
    alignSelf:'center',
    paddingBottom:10,
  },


});
  

export default FooterContact;