// Colours
const colours = {
  primary:'#3176EB',
  primaryDark:'#043399',
  primaryLight: '#74a4ff',

  primaryBlack:'#000000',
  secondaryBlack:'#212121',
  tertiaryBlack:'#424242',

  primaryGrey:'#808080',
  secondaryGrey:'#A9A9A9',
  tertiaryGrey:'#696969',
  lightGrey:'#bdbdbd',
  lighterGrey:'#e0e0e0',


  white:'#ffffff'

};
export default colours;
