
import 'react-native-gesture-handler';import React from 'react';

import { StyleSheet,View,Platform, Image, Text, TouchableOpacity, Dimensions, FlatList, ActivityIndicator, RefreshControl,ScrollView } from 'react-native';


import { NavigationContainer, DarkTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';


import HomeScreen from './homeScreen' 
import ContactPage from './contactPage';
import ExperiencePage from './experiencePage';
import ServicesPage from './servicesPage';
import AboutPage from './aboutPage';




const HomeStack = createStackNavigator();
function HomeStackScreen({route, navigation}) {


  return (
    <HomeStack.Navigator screenOptions={{ headerShown: false}}>
      <HomeStack.Screen name='Home' component={HomeScreen} options={{headerShown: false}}/>
      <HomeStack.Screen name='About Us' component={AboutPage} options={{headerShown: false}}/>
      <HomeStack.Screen name='Services' component={ServicesPage} options={{headerShown: false}}/>
      <HomeStack.Screen name='Experience' component={ExperiencePage} options={{headerShown: false}}/>
      <HomeStack.Screen name='Contact' component={ContactPage} options={{headerShown: false}}/>

    </HomeStack.Navigator>
  );
}

const linking = {
  prefixes: ['https://keenenter.com', 'keenenter://'],
  config: {
    screens: {
      HOME: '',
    }
  },
};


function HomeNavigator() {
  
  return (
    <View style={styles.navContainer}>

    <NavigationContainer linking={linking} theme={DarkTheme}>
      <HomeStackScreen />
    </NavigationContainer>
    </View>
  )
}

export default HomeNavigator;
const styles = StyleSheet.create({ 
  navContainer: { 
    ...Platform.select({ 
      native: { flex: 1, }, 
      web: { height: '100vh', }, 
    }), 
    }
  })